import styled from 'styled-components';
import {
  InnerContainer as StyledInnerContainer,
  COLORS,
  GUTTER_RESPONSIVE,
  Section,
  BUTTON_COLOR,
} from '../styles/homePageStyles';
import { PseudoFuzzyBackground } from '../pseudoFuzzyBackground/pseudoFuzzyBackground.styles';
import { LINK_COLOR } from '../link/link.styles';
import { media } from '../../../theme/media';
import { Image } from '../../image';

const HEIGHT_DEFAULT = 'clamp(60rem, min(95dvh, 52rem + 26.97vw), 73rem)';
const HEIGHT_COUNTDOWN = 'clamp(68rem, min(95dvh, 60rem + 26.97vw), 81rem)';

export const FundraisingSection = styled(Section)`
  background: transparent;

  width: 100%;

  position: relative;
  max-height: none;

  ${media.tablet`
    height: ${props => props.countdown ? HEIGHT_COUNTDOWN : HEIGHT_DEFAULT};
  `};
`;

export const OuterContainer = styled.div`
  position: relative;
  inset: 0;
  z-index: 1;
  margin: 0;

  ${media.tablet`
    padding-inline: ${GUTTER_RESPONSIVE};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  `};
`;

export const ImageContainer = styled.div`
  position: absolute;
  inset: 0 0 auto 0;
  z-index: 1;

  transition: transform 1s ease-out;
  animation-name: scale-in;
  animation-duration: 4s;

  @keyframes scale-in {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @media (prefers-reduced-motion) {
    animation: none;
  }

  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: clamp(50rem, 35rem + 60.88vw, 72rem);
    z-index: -1;
  }
`;

export const InnerContainer = styled(StyledInnerContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  margin-top: 80%;

  h2 {
    font-size: 2rem;
  }

  ${media.tablet`
    margin-top: 0;

    h2 {
      font-size: 2.656rem;
    }
  `};
`;

export const CountdownContainer = styled.div`
  text-align: center;
  padding: 0 0 1em 0;
  min-height: 100px;
  padding-top: 6em;

  ${media.tablet`
    text-align: left;
    padding-top: 1em;
  `};
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-top: 5em;

  ${media.tablet`
    padding-top: 76px;
    padding-bottom: 4vw;
    width: 465px;
  `}
`;

export const HeaderSection = styled(PseudoFuzzyBackground)`
  margin-bottom: 40px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  padding: 0 24px 0 24px;
  color: ${COLORS.WHITE};

  h2 {
    text-align: center;
    line-height: 1.18;
    margin: 0;
  }

  em, strong {
    color: ${props => props.colorTheme ? BUTTON_COLOR[props.colorTheme] : COLORS.YELLOW};
  }

  ${media.tablet`
    padding: 0;
    margin-bottom: 34px;

    h2 {
      text-align: left;
      line-height: 1.28;
      padding-right: 30px;
    }
  `}
`;

export const LowerContent = styled.div`
  background-color: ${COLORS.MEDIUM_DARK_GRAY};

  ${media.tablet`
    background-color: transparent;
  `}
`;

export const ImpactText = styled.div`
  --color-theme: ${props => props.theme ? LINK_COLOR[props.theme] : COLORS.YELLOW};
  padding: 1.5em 1.875em;
  color: ${COLORS.WHITE};
  font-weight: 400;
  text-align: center;
  white-space: pre-wrap;

  ${media.tablet`
    padding: 1.5em 0;
    text-align: left;
  `};

  a {
    text-decoration-color: var(--color-theme);
    word-wrap: break-word;
    vertical-align: top;
    display: inline;

    &:hover {
      background: var(--color-theme);
    }

    span {
      display: none;
    }
  }
`;

export const BadgeContainer = styled.div`
  display: inline-flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 1.875em;
  margin: 0.75em 0;
  width: 100%;
`;

export const BadgeImage = styled(Image)`
  height: 50px;

  ${media.tablet`
    height: 65px;
  `}
`;

export const MobilePadding = styled.div`
  height: 10vh;
`;
