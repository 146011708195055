import cookie from 'js-cookie';
import { parse } from 'query-string';

// Note: changing the values below may break the audience-specific dialog display logic in accessories.selectors.js
export const COOKIE_CONTENT_MAP = {
  'default': 'coldWarmContactContent',
  cc: 'coldWarmContactContent',
  wc: 'coldWarmContactContent',
  s: 'supporterEmailSubscriberContent',
  fm: 'fanMemberContent',
  v: 'volunteerContent',
  d: 'donorContent',
  md: 'monthlyDonorContent',
  ex: 'corporateExecAudienceContent',
};

export const getUserContentType = () => {
  const aud = parse(location.search).aud;
  if (COOKIE_CONTENT_MAP.hasOwnProperty(aud)) {
    return COOKIE_CONTENT_MAP[aud];
  }

  const code = cookie.get('asa_ct');
  if (typeof code === 'undefined' || !COOKIE_CONTENT_MAP.hasOwnProperty(code)) {
    return COOKIE_CONTENT_MAP.default;
  }

  return COOKIE_CONTENT_MAP[code];
};
