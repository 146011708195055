import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { getField, getUrls } from '../../utils/cmsData';
import { getUserContentType } from '../../utils/audienceCookies';

export const selectAccessoryDomain = state => state.accessories;

const disableForUserAudience = accessory => {
  if (getField('enableForAllAudiences', accessory)) {
    return false;
  }

  const userAudience = getUserContentType().replace('Content', '').trim();
  const capitalizedUserAudience = userAudience.charAt(0).toUpperCase() + userAudience.slice(1);
  const accessoryDisabled = getField(`disableFor${capitalizedUserAudience}`, accessory);
  return accessoryDisabled;
};

export const selectModalContent = createSelector(selectAccessoryDomain, state => {
  const modal = state.get('modalContent');
  if (modal.size === 0) {
    return modal;
  }
  // Return if in forbid list or not in allow list
  const forbidListUrls = getUrls(getField('forbidList', modal));
  const allowListUrls = getUrls(getField('allowList', modal));
  const fullUrl = `${location.protocol}//${location.hostname}${location.pathname}`;

  if (
    forbidListUrls &&
    (forbidListUrls.includes(location.pathname) || forbidListUrls.includes(fullUrl))
  ) {
    return Map();
  }

  if (allowListUrls && !allowListUrls.some(u => [location.pathname, fullUrl].includes(u))) {
    return Map();
  }

  // Return if disabled for the user's audience type
  if (disableForUserAudience(modal)) {
    return Map();
  }

  return modal;
});

export const selectBannerContent = createSelector(selectAccessoryDomain, state => {
  const banner = state.get('bannerContent');
  if (banner.size === 0) {
    return banner;
  }
  // Return if in forbid list or not in allow list
  const forbidListUrls = getUrls(getField('forbidList', banner));
  const allowListUrls = getUrls(getField('allowList', banner));
  const fullUrl = `${location.protocol}//${location.hostname}${location.pathname}`;
  if (
    forbidListUrls &&
    (forbidListUrls.includes(location.pathname) || forbidListUrls.includes(fullUrl))
  ) {
    return Map();
  }

  if (allowListUrls && !allowListUrls.some(u => [location.pathname, fullUrl].includes(u))) {
    return Map();
  }

  // Return if disabled for the user's audience type
  if (disableForUserAudience(banner)) {
    return Map();
  }

  return banner;
});

export const selectCurtainContent = createSelector(selectAccessoryDomain, state => {
  const curtain = state.get('curtainContent');
  if (curtain.size === 0) {
    return curtain;
  }
  // Return if in forbid list or not in allow list
  const forbidListUrls = getUrls(getField('forbidList', curtain));
  const allowListUrls = getUrls(getField('allowList', curtain));
  const fullUrl = `${location.protocol}//${location.hostname}${location.pathname}`;

  if (
    forbidListUrls &&
    (forbidListUrls.includes(location.pathname) || forbidListUrls.includes(fullUrl))
  ) {
    return Map();
  }

  if (allowListUrls && !allowListUrls.some(u => [location.pathname, fullUrl].includes(u))) {
    return Map();
  }

  // Return if disabled for the user's audience type
  if (disableForUserAudience(curtain)) {
    return Map();
  }

  return curtain;
});

export const selectIsModalOpen = createSelector(selectAccessoryDomain, state =>
  state.get('isModalOpen'),
);
