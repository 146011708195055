import styled from 'styled-components';

import { media } from '../../../theme/media';
import {
  Section as SectionComponent,
  H2 as H2Component,
  OUTER_GRID_WIDTH,
  PADDING_HORIZONTAL_SMALL,
  PADDING_HORIZONTAL_LARGE,
  GUTTER_RESPONSIVE,
  COLORS,
} from '../styles/homePageStyles';
export { Button } from '../button/button.styles';

// Ensure that text extends to first hairline of 3-up scrolling block
const WIDE_BREAKPOINT = `calc(${OUTER_GRID_WIDTH} + ${GUTTER_RESPONSIVE} * 2)`;
const WIDE_GUTTER = `calc((100vw - ${OUTER_GRID_WIDTH}) / 2)`;
const STORY_WIDTHS = {
  NARROW: `calc((100vw - ${GUTTER_RESPONSIVE} * 4)/3)`,
  WIDE: `calc((${OUTER_GRID_WIDTH} - ${GUTTER_RESPONSIVE} * 2) / 3)`,
};
const TEXT_WIDTHS = {
  NARROW: `calc(${STORY_WIDTHS.NARROW} + ${GUTTER_RESPONSIVE} * 1.5)`,
  WIDE: `calc(${STORY_WIDTHS.WIDE} + ${WIDE_GUTTER} + ${GUTTER_RESPONSIVE} / 2)`,
};

export const Section = styled(SectionComponent)`
  background-color: ${COLORS.BLACK};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`
    flex-direction: row;
    max-height: 600px;
  `};
`;

export const TextSection = styled.div`
  order: 2;
  padding: ${GUTTER_RESPONSIVE};
  // Ensure bottom padding is equal to top padding plus line-height difference
  padding-bottom: clamp(${PADDING_HORIZONTAL_SMALL} + 12.5px, 6.16vw + 12.5px, ${PADDING_HORIZONTAL_LARGE} + 12.5px);

  ${media.tablet`
    order: 1;
    width: ${TEXT_WIDTHS.NARROW};
    // Ensure that text always has some left padding,
    // and that on wide screens, it starts at outer content boundary
    padding-inline-start: max(${GUTTER_RESPONSIVE}, ${WIDE_GUTTER});
    padding-bottom: ${GUTTER_RESPONSIVE};
  `};

  @media screen and (min-width: ${WIDE_BREAKPOINT}) {
    width: ${TEXT_WIDTHS.WIDE};
  }
`;

export const InnerText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  color: ${COLORS.WHITE};
  word-wrap: break-word;
`;

export const H2 = styled(H2Component)`
  line-height: 1.25;
  font-weight: 700;

  ${media.tablet`
    line-height: 1.1;
    font-size: clamp(2rem, 4.25vw, 2.5rem);
  `}
`;

export const ImageSection = styled.div`
  order: 1;
  line-height: 0;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.tablet`
    order: 2;
    position: absolute;
    width: calc(100% - ${TEXT_WIDTHS.NARROW});
    left: ${TEXT_WIDTHS.NARROW};
  `};

  @media screen and (min-width: ${WIDE_BREAKPOINT}) {
    width: calc(100% - ${TEXT_WIDTHS.WIDE});
    left: ${TEXT_WIDTHS.WIDE};
  }
`;
