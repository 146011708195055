import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ValuesSection,
  ValueItemDefault,
  ValueItemInput,
  InputContainer,
} from './valueSelector.styles';

export const ValueSelector = ({
  values,
  theme,
  isMonthly,
  activeValue,
  customValue,
  handleValueChange,
  setCustomValue,
}) => {
  const customInputRef = useRef(null);
  const themeBase = theme.split('_ACCESSIBLE')[0].toLowerCase();
  const [inputKeyboardFocused, setInputKeyboardFocused] = useState(false);

  const isActiveValue = (id) => {
    return isMonthly ? id === activeValue.monthly : id === activeValue.oneTime;
  };

  const inputActive = isActiveValue(3);

  const getValue = (id) => {
    return isMonthly ? values.monthlyValues[id] : values.oneTimeValues[id];
  };

  const handleCustomChange = (e) => {
    setCustomValue(e.target.value);
  };

  // Create Button accessiblity for ValueItems
  // Converting them directly to Buttons breaks flex on mobile iOS
  const handleKeyDown = (event, i) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleValueChange(i);
    }
  };

  const generateDefaultValueItems = () => {
    return [0, 1, 2].map((i) =>
      <ValueItemDefault
        active={isActiveValue(i)}
        onClick={() => handleValueChange(i)}
        inputActive={inputActive}
        isMonthly={isMonthly}
        key={i}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => handleKeyDown(e, i)}
      >
        <sup key={`dollar-sign-${i}`}>$</sup>
        {getValue(i)}
        {isMonthly && <sub key={`per-month-${i}`}>/mo</sub>}
      </ValueItemDefault>
    );
  };

  const handleInputClick = () => {
    handleValueChange(3);
    customInputRef.current.focus();
    setInputKeyboardFocused(false);
  };

  const handleInputKeyUp = (event) => {
    if (event.key === 'Tab') {
      setInputKeyboardFocused(true);
    } else {
      handleKeyDown(event, 3);
    }
    customInputRef.current.focus();
  };

  const handleInputBlur = () => {
    setInputKeyboardFocused(false);
  };

  useEffect(() => {
    if (customInputRef.current && inputActive && !customValue) {
      customInputRef.current.focus();
    }
  }, [isMonthly]);

  return (
    <ValuesSection theme={theme} themeBase={themeBase}>
      {generateDefaultValueItems()}
      <ValueItemInput
        active={inputActive}
        keyboardFocused={inputKeyboardFocused}
        length={customValue.toString().length}
        isMonthly={isMonthly}
        onClick={handleInputClick}
        onKeyUp={handleInputKeyUp}
        onBlur={handleInputBlur}
      >
        <label htmlFor="otherAmount">OTHER</label>
        <InputContainer>
          <sup>$</sup>
          <input
            type="number"
            name="otherAmount"
            ref={customInputRef}
            onChange={handleCustomChange}
          ></input>
          {isMonthly && <sub>/mo</sub>}
        </InputContainer>
      </ValueItemInput>
    </ValuesSection>
  );
};

ValueSelector.propTypes = {
  values: PropTypes.shape({
    monthlyValues: PropTypes.arrayOf(PropTypes.number).isRequired,
    oneTimeValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  theme: PropTypes.string,
  isMonthly: PropTypes.bool,
  activeValue: PropTypes.object.isRequired,
  customValue: PropTypes.string.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  setCustomValue: PropTypes.func.isRequired,
};

ValueSelector.defaultProps = {
  theme: 'BLUE',
  isMonthly: true,
};

export default ValueSelector;
