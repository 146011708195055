import styled from 'styled-components';
import { media } from '../../../theme/media';

import {
  Section as SectionComponent,
  COLORS,
  MASK_IMAGE_NOTCH,
  RESPONSIVE_BOTTOM_MARGIN,
  OUTER_GRID_WIDTH,
} from '../styles/homePageStyles';
import { VideoPlayIcon as VideoPlayIconComponent } from '../videoPlayIcon';
export { OuterContainer, Pad, H2, SmallText } from '../styles/homePageStyles';

export const Section = styled(SectionComponent)`
  background-color: ${COLORS.WHITE};
  margin-block: 75px 40px;

  ${media.tablet`
    margin-block-start: ${RESPONSIVE_BOTTOM_MARGIN};
    margin-block-end: 50px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.DARK_GRAY};
  row-gap: 1.5rem;

  ${media.tablet`
    flex-direction: row;
    column-gap: 50px;
    align-items: stretch;
  `};
`;

export const ImageSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.875rem;

  a {
    position: relative;
  }

  ${media.tablet`
    justify-content: flex-start;

    a {
      height: 100%;
    }
  `};
`;

export const ImageContainer = styled.div`
  ${media.tablet`
    height: 100%;
  `}
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  mask-image: ${MASK_IMAGE_NOTCH};

  ${media.tablet`
    height: 100%;
    max-height: min(50vw, calc(${OUTER_GRID_WIDTH} / 2));
    min-height: 100%;
    width: 100%;
    object-fit: cover;
  `};
`;

export const VideoPlayIcon = styled(VideoPlayIconComponent)`
  mask-image: ${MASK_IMAGE_NOTCH};

  img {
    height: initial;
    left: 2%;
  }
`;

export const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;

  ${media.tablet`
    row-gap: 1.4rem;
    & > * {
      max-width: 475px;
    }
    `};
`;

export const Title = styled.div`
  display: ${props => (props.displayOnMobile ? 'block' : 'none')};
  line-height: 1;
  word-wrap: break-word;

  h2 {
    font-weight: 700;
  }

  a {
    display: inline-block;
  }

  ${media.tablet`
    display: ${props => (props.displayOnDesktop ? 'block' : 'none')};
    margin-block-end: 0.7rem;

    h2 {
      font-size: clamp(2.656rem, 4.188rem, 5vw);
    }
  `};
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 0.4rem;
`;
