import styled from 'styled-components';
import { COLORS, FONTS, H2 } from '../styles/homePageStyles';
import { media } from '../../../theme/media';

export const HEIGHT = 16;
const HEIGHT_ADJUSTMENT = 42;
export const TOP_SCROLL = 100;
export const STICK_DURATION = 200;
export const CATCH_UP_SCROLL = STICK_DURATION - HEIGHT;
export const HEADER_ADJUSTMENT = 76;

export const calculateHeight = windowHeight => {
  let calcHeight = 0;

  if (windowHeight > 760) {
    // on larger screens the height of the component doesn't need to be responsive
    calcHeight = 560;
  } else {
    // on smaller screens, the component should be 75% of the screen height
    calcHeight = windowHeight * 0.75;
  }

  // however, if someone artificially makes their screen height too small,
  // we need to make sure the component is at least 28rem tall
  return calcHeight > 448 ? calcHeight : 448;
};

export const calculatePadding = windowHeight => {
  return calculateHeight(windowHeight) / 2 - HEIGHT_ADJUSTMENT;
};

export const calculateMobilePadding = windowHeight => {
  return calculateHeight(windowHeight) / 3.85 - HEIGHT;
};

export const AnchoredScrollTextContainer = styled.div`
  // to avoid flash on initial load
  visibility: ${props => (props.componentTop === 0 ? 'hidden' : 'visible')};
  width: 100%;
  position: absolute;
  opacity: ${props => (props.isTransitioning ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
  top: ${props => props.componentTop}px;

  #scroll-statement-1 {
    opacity: 1;
    z-index: 3;
  }

  #scroll-statement-2 {
    z-index: 3;
  }

  #scroll-statement-3 {
    z-index: 4;
  }

  #scroll-statement-4 {
    z-index: 5;
  }

  h2 {
    color: ${COLORS.WHITE};
  }
`;

export const TextContainer = styled.div`
  max-width: 24rem;
  padding-top: ${props => calculateMobilePadding(props.windowHeight)}px;
  margin: 0 auto;

  ${media.tabletLarge`
    max-width: 100%;
    padding-top: ${props => calculatePadding(props.windowHeight)}px;
    margin: auto;
  `};
`;

export const AnchoredScrollTextPlaceholder = styled.div`
  background: ${COLORS.LIGHT_GRAY};
  width: 100%;
  height: ${props => calculateHeight(props.windowHeight - 0.5)}px;
  opacity: ${props => (props.isTransitioning ? 1 : 0)};
  height: 40.45rem;
  transition: opacity 1.5s ease-in;

  ${media.tabletLarge`
    height: ${props => calculateHeight(props.windowHeight) - 0.5}px;
  `};
`;

export const AllOtherContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  visibility: ${props => (props.ready ? 'visible' : 'hidden')};
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${props => calculateHeight(props.windowHeight)}px;
  overflow: hidden;
  z-index: 1;

  > section {
    z-index: 10;
    position: absolute;
    bottom: 0;
  }

  ${media.tabletLarge`
    height: ${props => calculateHeight(props.windowHeight)}px;
  `};
`;

export const BackgroundImage = styled.div`
  position: absolute;
  top: -2rem;
  left: 0;
  width: 100vw;
  height: 55rem;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: ${props => (props.isMobile ? '68%' : 'center')};
  transform: translateY(0);

  ${media.tabletLarge`
    height: 45rem;
  `};
`;

export const AnchorText = styled(H2)`
  margin-bottom: ${props => `-${CATCH_UP_SCROLL * props.numberOfStatements}px`};
  font-family: ${FONTS.TITLE_FONT};
  font-size: 32px;
  text-align: center;
  padding-bottom: 1rem;
  font-weight: 700;
  z-index: 3;

  svg {
    position: relative;
    top: 3px;
    margin-bottom: 16%;

    ${media.tabletLarge`
      margin-bottom: auto;
      margin-right: 50%;
  `};
  }

  ${media.tabletLarge`
    font-size: 42.5px;
    margin-bottom: ${props => `-${CATCH_UP_SCROLL * props.numberOfStatements + 69}px`};
    text-align: left;
    padding-bottom: 0;
    width: 100%;
    text-align: right;

    #anchored-because {
      position: relative;
      right: 2%;
    }
  `};
`;

export const ScrollText = styled(H2)`
  font-family: ${FONTS.MAIN_FONT};
  font-style: italic;
  position: relative;
  height: ${HEIGHT}px;
  font-size: 32px;
  width: auto;
  filter: blur(0px);
  transition: filter 0.3s ease-out;
  text-align: center;
  margin-top: -${HEIGHT * 2}px;
  opacity: ${props => (props.forceOpacity ? '1 !important' : 0)};
  padding: 0 0.75rem;
  overflow: visible;

  a {
    display: block;
    font-style: normal;
    text-transform: none;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 2.5rem;
    font-size: 18px;

    ${media.tabletLarge`
      margin: 0 0;
      margin-top: 2.5rem;
    `};
  }

  ${media.tabletLarge`
    margin-left: 286px;
    text-align: left;
    margin-top: -${HEIGHT * 2}px;
    text-align: left;
    font-size: 42.5px;
    margin-right: 5%;
    margin-left: 52%;
    padding: 0 0 0 6px;
  `};
`;
