import React from 'react';
import PropTypes from 'prop-types';
import { DonationBox, TYPES } from '../donationBox';
import { Image } from '../image';
import {
  GivingSection,
  OuterContainer,
  ImageContainer,
  InnerContainer,
  Content,
  HeaderSection,
  BoldHeader,
  ItalicHeader,
  MendedHeart,
  LinkSection,
  Link,
  MobilePadding,
} from './givingBreakout.styles';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';

import MendedHeartSvg from '../../../images/icon-heart.svg';

export const GivingBreakout = ({
  heroDesktop,
  heroMobile,
  description,
  givingValues,
  preselectedMonthly,
  preselectedOneTime,
}) => {
  const valuesObject = {
    monthlyValues: [
      givingValues.firstMonthly,
      givingValues.secondMonthly,
      givingValues.thirdMonthly,
    ],
    oneTimeValues: [
      givingValues.firstOneTime,
      givingValues.secondOneTime,
      givingValues.thirdOneTime,
    ],
  };

  const getDynamicHero = (isMobile) => {
    if (isMobile) {
      return heroMobile.getIn(['fields', 'file', 'url']);
    }
    return heroDesktop.getIn(['fields', 'file', 'url']);
  };

  const getAlt = (isMobile) => {
    if (isMobile) {
      return heroMobile.getIn(['fields', 'title']);
    }
    return heroDesktop.getIn(['fields', 'title']);
  };

  return (
    <PlatformDetector>
      {({ isMobile }) => (
        <GivingSection>
          <OuterContainer>
            <ImageContainer>
              <Image
                src={getDynamicHero(isMobile)}
                alt={getAlt(isMobile)}
              />
            </ImageContainer>
            <InnerContainer>
              <Content>
                <HeaderSection theme="light">
                  <BoldHeader>Defend Animals</BoldHeader>
                  <ItalicHeader inLine>every day</ItalicHeader>
                  <MendedHeart>
                    <img src={MendedHeartSvg} alt="" loading="lazy" />
                  </MendedHeart>
                </HeaderSection>
                <DonationBox
                  values={valuesObject}
                  description={description}
                  theme="BLUE_ACCESSIBLE"
                  type={TYPES.CHECK}
                  preselectedMonthly={preselectedMonthly - 1}
                  preselectedOneTime={preselectedOneTime - 1}
                >
                </DonationBox>
                <LinkSection>
                  <Link
                    url="/ways-to-give"
                    text="More ways to give"
                  />
                </LinkSection>
                {isMobile && <MobilePadding />}
              </Content>
            </InnerContainer>
          </OuterContainer>
        </GivingSection>
      )}
    </PlatformDetector>
  );
};

GivingBreakout.propTypes = {
  heroDesktop: PropTypes.object.isRequired,
  heroMobile: PropTypes.object.isRequired,
  description: PropTypes.string,
  givingValues: PropTypes.shape({
    firstMonthly: PropTypes.number.isRequired,
    secondMonthly: PropTypes.number.isRequired,
    thirdMonthly: PropTypes.number.isRequired,
    firstOneTime: PropTypes.number.isRequired,
    secondOneTime: PropTypes.number.isRequired,
    thirdOneTime: PropTypes.number.isRequired,
  }).isRequired,
  preselectedMonthly: PropTypes.oneOf([1, 2, 3]),
  preselectedOneTime: PropTypes.oneOf([1, 2, 3]),
};

GivingBreakout.defaultProps = {
  preselectedMonthly: 2,
  preselectedOneTime: 2,
};

export default GivingBreakout;
